<template>
<v-container>
  <div>
    <navbar title="حساب های من" route= '/panel'/>
     <v-main>
       <div v-for="purse in purses" :key="purse.Purse_ID" >
         <wallets :purse = 'purse' />
       </div>
     </v-main>  
  </div>
</v-container>
</template>
<script>
import navbar from '@/components/navbar/appBar'
import wallets from './components/cards/walletsList'
import { getPurse } from './models/purse'
import { mapState } from 'vuex'
export default {
    components:{
        navbar,
        wallets
    },
    created () {
      getPurse()
    },
    computed: {
      ...mapState({
        purses: state => state.purse.purse
      })
    }

}
</script>